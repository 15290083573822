import React, {useEffect, useRef, useState} from 'react';
import { useTranslation } from 'react-i18next';
import {useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import {Check, FavoriteBorder, Favorite} from '@mui/icons-material';
import {Card, CardBody} from "reactstrap";
import DatePicker from "react-datepicker";

import i18n from "../../../i18n";
import is from "date-fns/locale/is";
import RenderPdfFileContainer from "../components/renderPdfFileContainer";
import './index.scss';

import GreenStepMunicipality from "../components/customGreenSteps/greenStepMunicipality";
import GreenStepDefault from "../components/customGreenSteps/greenStepDefault";
import GreenStep62 from "../components/customGreenSteps/greenStep62";
import GreenStep142 from "../components/customGreenSteps/greenStep142";
import GreenStep144 from "../components/customGreenSteps/greenStep144";
import GreenStep154 from "../components/customGreenSteps/greenStep154";
import GreenStep155 from "../components/customGreenSteps/greenStep155";
import GreenStep157 from "../components/customGreenSteps/greenStep157";
import GreenStep168 from "../components/customGreenSteps/greenStep168";
import GreenStep173 from "../components/customGreenSteps/greenStep173";
import GreenStep177 from "../components/customGreenSteps/greenStep177";
import GreenStep178 from "../components/customGreenSteps/greenStep178";
import GreenStep179 from "../components/customGreenSteps/greenStep179";
import GreenStep191 from "../components/customGreenSteps/greenStep191";
import GreenStep196 from "../components/customGreenSteps/greenStep196";
import GreenStep197 from "../components/customGreenSteps/greenStep197";
import GreenStep201 from "../components/customGreenSteps/greenStep201";
import GreenStep202 from "../components/customGreenSteps/greenStep202";
import GreenStep203 from "../components/customGreenSteps/greenStep203";
import GreenStep204 from "../components/customGreenSteps/greenStep204";
import GreenStep205 from "../components/customGreenSteps/greenStep205";
import GreenStep206 from "../components/customGreenSteps/greenStep206";
import GreenStep207 from "../components/customGreenSteps/greenStep207";
import GreenStep210 from "../components/customGreenSteps/greenStep210";
import GreenStep211 from "../components/customGreenSteps/greenStep211";
import GreenStep212 from "../components/customGreenSteps/greenStep212";
import GreenStep213 from "../components/customGreenSteps/greenStep213";
import GreenStep214 from "../components/customGreenSteps/greenStep214";
import GreenStep215 from "../components/customGreenSteps/greenStep215";
import GreenStep216 from "../components/customGreenSteps/greenStep216";
import GreenStep217 from "../components/customGreenSteps/greenStep217";
import GreenStep218 from "../components/customGreenSteps/greenStep218";
import GreenStep219 from "../components/customGreenSteps/greenStep219";

const Index = (props) => {
  const { t } = useTranslation();
  const {bigCategory, mainCategoryIndex} = props;
  const currentLang = i18n.language || 'is';
  const userData = JSON.parse(localStorage.getItem('p_user'));
  const {state} = useLocation();
  const cardRef = useRef([]);
  const firstCompany = userData && userData.companies.length>0 ? userData.companies[0] : false;
  const isInstitution = firstCompany && firstCompany.municipality_id>0 && firstCompany.type === null;

  const {municipalities} = useSelector( state => state.municipality );

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [favoriteItem, setFavoriteItem] = useState(null);
  const [myMunicipality, setMyMunicipality] = useState([]);


  useEffect(() => {
    if(municipalities && isInstitution) {
      let municipality = municipalities.results.find(f => f.id === firstCompany.municipality_id);
      if(municipality) {
        setMyMunicipality(municipality);
      }
    }
  }, [municipalities]);

  useEffect(() => {
    if(bigCategory && state && state.addOwnCarbon) {
      setTimeout(function(){
        cardRef?.current[state.addOwnCarbon]?.scrollIntoView()
      }, 500);
      // window.scrollTo({ top: cardRef.current[state.addOwnCarbon]?.getBoundingClientRect().top, behavior: 'smooth'})
    }
  },[state, bigCategory])

  const toggleGreenStep = (categoryItem) => {
   if(categoryItem.new_company_green_steps.length === 0 || (categoryItem.new_company_green_steps.length > 0 && categoryItem.new_company_green_steps[0].hasOwnProperty('deletedItem'))) {
      let data = {
        "new_green_step_id": categoryItem.id,
        "comment": [],
        "irrelevant": false,
        "reason": []
      };
      props.check(data);
    } else {
     props.unCheck(categoryItem.new_company_green_steps[0].id);
    }
  };

  const onChangeFavoriteDate = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const saveFavorite = () => {
    props.addActionPlan(favoriteItem.title, favoriteItem.id, startDate, endDate);
    setFavoriteItem(null);
  };

  const customGreenSteps = [
    62, 142, 144, 154, 155, 157, 168, 173, 177, 178, 179, 191, 196, 197, 201,
    202, 203, 204, 205, 206, 207, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219
  ];

  return (
    <div className="row greenStepBigCategory">

      {bigCategory && bigCategory.map(category =>
        <React.Fragment key={"bigCategoryFragment"+category.id}>
          {
            category.new_green_steps_not_completed.map((item, categoryIndex) => {
              let desc = item["description_" + currentLang] ? item["description_" + currentLang] : item.description;
              let descHasLink = desc && desc.includes('http');
              let companyGreenStep = item.new_company_green_steps.length>0 ? item.new_company_green_steps[0] : false;
              let companyGreenStepIsIrrelevant = companyGreenStep && companyGreenStep.hasOwnProperty('irrelevant') && companyGreenStep.irrelevant === true;
              return (
                <div className="col col-12" key={"renderBigCategory_greenSteps_"+categoryIndex} ref={el => cardRef.current[item.id] = el}>
                  <Card>
                    <CardBody>
                      {!customGreenSteps.includes(item.id) && !isInstitution &&
                        <GreenStepDefault
                          item={item}
                          descHasLink={descHasLink}
                          desc={desc}
                          companyGreenStep={companyGreenStep}
                          companyGreenStepIsIrrelevant={companyGreenStepIsIrrelevant}
                        />
                      }

                      {!customGreenSteps.includes(item.id) && isInstitution && item.type === "municipality" &&
                        <GreenStepMunicipality
                          item={item}
                          descHasLink={descHasLink}
                          desc={desc}
                          myMunicipality={myMunicipality}
                        />
                      }

                      {item.id === 62 && <GreenStep62 item={item} companyGreenStep={companyGreenStep} /> }
                      {item.id === 142 && <GreenStep142 item={item} descHasLink={descHasLink} desc={desc} companyGreenStep={companyGreenStep} /> }
                      {item.id === 144 && <GreenStep144 item={item} descHasLink={descHasLink} desc={desc} companyGreenStep={companyGreenStep} /> }
                      {item.id === 154 && <GreenStep154 item={item} descHasLink={descHasLink} desc={desc}/>}
                      {item.id === 155 && <GreenStep155 item={item} descHasLink={descHasLink} desc={desc} companyGreenStep={companyGreenStep} companyGreenStepIsIrrelevant={companyGreenStepIsIrrelevant}/>}
                      {item.id === 157 && <GreenStep157 item={item} descHasLink={descHasLink} desc={desc} companyGreenStep={companyGreenStep} companyGreenStepIsIrrelevant={companyGreenStepIsIrrelevant}/>}
                      {item.id === 168 && <GreenStep168 item={item} descHasLink={descHasLink} desc={desc} companyGreenStep={companyGreenStep} /> }
                      {item.id === 173 && <GreenStep173 item={item} descHasLink={descHasLink} desc={desc} companyGreenStep={companyGreenStep} /> }
                      {item.id === 177 && <GreenStep177 item={item} descHasLink={descHasLink} desc={desc} companyGreenStep={companyGreenStep} /> }
                      {item.id === 178 && <GreenStep178 item={item} descHasLink={descHasLink} desc={desc} companyGreenStep={companyGreenStep} /> }
                      {item.id === 179 && <GreenStep179 item={item} descHasLink={descHasLink} desc={desc} companyGreenStep={companyGreenStep} /> }
                      {item.id === 191 && <GreenStep191 item={item} descHasLink={descHasLink} desc={desc} companyGreenStep={companyGreenStep} /> }
                      {item.id === 196 && <GreenStep196 item={item} descHasLink={descHasLink} desc={desc} companyGreenStep={companyGreenStep} /> }
                      {item.id === 197 && <GreenStep197 item={item} descHasLink={descHasLink} desc={desc} companyGreenStep={companyGreenStep} /> }
                      {item.id === 201 && <GreenStep201 item={item} descHasLink={descHasLink} desc={desc} companyGreenStep={companyGreenStep} /> }
                      {item.id === 202 && <GreenStep202 item={item} descHasLink={descHasLink} desc={desc} companyGreenStep={companyGreenStep} /> }
                      {item.id === 203 && <GreenStep203 item={item} descHasLink={descHasLink} desc={desc} companyGreenStep={companyGreenStep} /> }
                      {item.id === 204 && <GreenStep204 item={item} descHasLink={descHasLink} desc={desc} companyGreenStep={companyGreenStep} /> }
                      {item.id === 205 && <GreenStep205 item={item}/> }
                      {item.id === 206 && <GreenStep206 item={item} isInstitution={isInstitution}/> }
                      {item.id === 207 && <GreenStep207 item={item} descHasLink={descHasLink} desc={desc} companyGreenStep={companyGreenStep} /> }
                      {item.id === 210 && <GreenStep210 item={item} descHasLink={descHasLink} desc={desc} companyGreenStep={companyGreenStep} /> }
                      {item.id === 211 && <GreenStep211 item={item} descHasLink={descHasLink} desc={desc} companyGreenStep={companyGreenStep} /> }
                      {item.id === 212 && <GreenStep212 item={item} descHasLink={descHasLink} desc={desc} companyGreenStep={companyGreenStep} /> }
                      {item.id === 213 && <GreenStep213 item={item} descHasLink={descHasLink} desc={desc} companyGreenStep={companyGreenStep} /> }
                      {item.id === 214 && <GreenStep214 item={item} descHasLink={descHasLink} desc={desc} companyGreenStep={companyGreenStep} /> }
                      {item.id === 215 && <GreenStep215 item={item} descHasLink={descHasLink} desc={desc} companyGreenStep={companyGreenStep} /> }
                      {item.id === 216 && <GreenStep216 item={item} descHasLink={descHasLink} desc={desc} companyGreenStep={companyGreenStep} /> }
                      {item.id === 217 && <GreenStep217 item={item} descHasLink={descHasLink} desc={desc} companyGreenStep={companyGreenStep} /> }
                      {item.id === 218 && <GreenStep218 item={item} descHasLink={descHasLink} desc={desc} companyGreenStep={companyGreenStep} /> }
                      {item.id === 219 && <GreenStep219 item={item} companyGreenStep={companyGreenStep} /> }

                      {item.quotation &&
                        <div class="quotation categoryTextColor">
                          {item.quotation}
                        </div>
                      }
                    </CardBody>
                  </Card>
                </div>
              )
            })
          }

          {
            category.categories.map((item, categoryIndex) => {
              let allCategories = item.new_green_steps ? item.new_green_steps.filter(f => f.id !== 188 && f.id !== 189) : [];
              let emptyCategoryGreenStep = item.new_green_steps ? item.new_green_steps.find(f => f.id === 188 || f.id === 189) : false;
              let notCheckedCategories = allCategories.length>0 ? allCategories.filter(f => f.new_company_green_steps.length ===0) : [];
              return (
                <div className="col col-12" key={"renderBigCategory_categories_" + categoryIndex}>
                  <Card>
                    <CardBody>
                      <p className="score categoryTextColor">10 {t('pluses_page.points')}</p>

                      <div className="favorite">
                        {allCategories.length>0 && allCategories[0].company_plan_actions.length>0 ?
                          <Favorite
                            className="categoryTextColor"
                            onClick={() => props.deleteActionPlan(allCategories[0].company_plan_actions[0].id)}
                          />
                          :
                          <FavoriteBorder
                            className="categoryTextColor"
                            onClick={() => setFavoriteItem(
                              {
                                title: notCheckedCategories.length>0 ? `${item.name} (${notCheckedCategories.map(m => m.title).join(', ')})` : item.name,
                                id:  allCategories[0].id
                              }
                            )}
                          />
                        }
                      </div>

                      {favoriteItem && favoriteItem.id === allCategories[0].id &&
                      <div className="favoriteCalendar">
                        <div className="header categoryTextColor">Tímasetja aðgerð</div>
                        <DatePicker
                          selected={startDate}
                          onChange={onChangeFavoriteDate}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          startDate={startDate}
                          endDate={endDate}
                          dropdownMode="select"
                          selectsRange
                          selectsDisabledDaysInRange
                          inline
                          locale={is}
                        />
                        <div className="footer">
                          <div className="cancel" onClick={() => setFavoriteItem(null)}>{t('cancel')}</div>
                          <div className="save " onClick={() => saveFavorite()}>{t('save')}</div>
                        </div>
                      </div>
                      }

                      <div className="title col-sm-12 col-md-12 col-lg-12 col-xl-8 col-xxl-8 categoryTextColor">
                        {item["name_" + currentLang] ? item["name_" + currentLang] : item.name}
                      </div>

                      <p className="cardDesc col-sm-12 col-md-12 col-lg-12 col-xl-8 col-xxl-8">
                        {item["description_" + currentLang] ? item["description_" + currentLang] : item.description}
                      </p>

                      <div className="row">
                        {
                          allCategories.map((catItem, itemIndex) =>
                            <div className="col-6" key={"smallCategoryCategories" + categoryIndex + "_categories" + itemIndex}>
                              <div className="companyCompletedToggleBtns">
                                <div className="companyCompleted" onClick={() => toggleGreenStep(catItem)}>
                                  <div
                                    className={`companyCompletedIcon categoryBorderColor checkbox ${catItem.new_company_green_steps.length > 0 && !catItem.new_company_green_steps[0].hasOwnProperty('deletedItem')}`}
                                  >
                                    <Check/>
                                  </div>
                                  <div className="companyCompletedTitle">
                                    {catItem["title_" + currentLang] ? catItem["title_" + currentLang] : catItem.title}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        }
                      </div>

                      <div className="confirm_container" style={{marginTop: '20px'}} >
                        <div className="confirm_title categoryTextColor">
                          {emptyCategoryGreenStep ?
                            emptyCategoryGreenStep["pdf_description_"+currentLang] ?
                              emptyCategoryGreenStep["pdf_description_"+currentLang] : emptyCategoryGreenStep.pdf_description
                          : '' }
                        </div>
                        <div className="confirm_title ">
                          {emptyCategoryGreenStep ?
                            emptyCategoryGreenStep["pdf_description2_"+currentLang] ?
                              emptyCategoryGreenStep["pdf_description2_"+currentLang] : emptyCategoryGreenStep.pdf_description2
                            : '' }
                        </div>

                        <RenderPdfFileContainer
                          categoryItem={emptyCategoryGreenStep}
                          mainCategoryIndex={mainCategoryIndex}
                          key={"renderBigCategory_categories_" + categoryIndex+"_renPDFCon"}
                          requiredFrom={false}
                        />
                      </div>

                    </CardBody>
                  </Card>
                </div>
              )
            })
          }
        </React.Fragment>
      )}

    </div>
  )
};

export default Index